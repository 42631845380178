$( function() {
  scroll_init();
  if($(".js-slick").length) slick_init();
  if($('.js-nav-trigger').length) nav_init();
  if($('.form-cont span.error').length) {
    $('.form-cont span.error').each(function() {
      $(this).parents('dd').find('input, textarea').addClass('is-error');
    });
    $('.form-cont.form-en span.error').html('There is an error.');
    $('.form-cont.form-zh-tw span.error').html('輸入內容有誤');
    $('.form-cont.form-zh-cn span.error').html('输入内容有误');
  }

  $('.js-nav-trigger').on('click', function() {
    $('.l-header').toggleClass('is-open');
  });

  $(window).on('load', function() {
    if($('.js-pageTop').length) pagetop_init();
  });

});

function scroll_init() {
  $('a[href^=#]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top -120;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}

function slick_init() {

  $('.js-slick').slick({
    // アクセシビリティ。左右ボタンで画像の切り替えをできるかどうか
    accessibility: false,
    // 自動再生。trueで自動再生される。
    autoplay: true,
    // 自動再生で切り替えをする時間
    autoplaySpeed: 2000,
    // 自動再生や左右の矢印でスライドするスピード
    speed: 400,
    // 自動再生時にスライドのエリアにマウスオンで一時停止するかどうか
    pauseOnHover: true,
    // 自動再生時にドットにマウスオンで一時停止するかどうか
    pauseOnDotsHover: true,
    // 切り替えのアニメーション。ease,linear,ease-in,ease-out,ease-in-out
    cssEase: 'ease-in-out',
    // 画像下のドット（ページ送り）を表示
    dots: true,
    // ドットのclass名をつける
    dotsClass: 'dot-class',
  });
}


function pagetop_init() {
  var topBtn = $('.js-pageTop');
  var point = $('.l-footer').offset().top -150;
  //スクロールが300に達したらボタン表示
  $(window).scroll(function () {
    var st = $(this).scrollTop();

    if(!$('body.lang-ja').length) {
      if (st > 200) {
        $('.l-header').addClass('is-fixed');
      } else {
       $('.l-header').removeClass('is-fixed');
      }
    } else {
      var wh = $(window).height();
      if(st >= wh) {
        $('.l-header').removeClass('is-pageTop');
      } else {
        $('.l-header').addClass('is-pageTop');
      }
      if (st > 1000 + wh) {
        $('.l-header').addClass('is-fixed');
      } else {
       $('.l-header').removeClass('is-fixed');
      }
    }

    if (st + $(window).height() > point) {
      // var bottom = st + + $(window).height() - point + 200;
      topBtn.css({'bottom' :  200 + "px" });
    } else {
      topBtn.css({'bottom' : 40+"px" });
    }


    if(!$('body.lang-ja').length) {
      if (st > 300) {
        topBtn.addClass('is-show');
        $('.l-header').addClass('is-show');
      } else {
        topBtn.removeClass('is-show');
        $('.l-header').removeClass('is-show');
      }    } else {
      if (st > 1100 + wh) {
        topBtn.addClass('is-show');
        $('.l-header').addClass('is-show');
      } else {
        topBtn.removeClass('is-show');
        $('.l-header').removeClass('is-show');
      }
    }


  });
  //スクロールしてトップ
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });
}

function nav_init() {
  $('.js-nav-trigger').on('click', function(){
    $('body').toggleClass('nav-open');
    return false;
  });
}


// JP top用
var st = 0;

$( function() {
  if($('body.lang-ja').length) {
    $(window).on('load', function() {
      $('.top-kv-slideshow').addClass('is-loaded');
      setTimeout(function() {
        // キービジュアル スライドショー
        setInterval(function() {
          var current = $('.top-kv-slideshow .is-current');
          var next = current.next('.slide');
          if(!next.length) next = $('.top-kv-slideshow .slide').eq(0);
          next.addClass('is-next');
          current.fadeOut(500,function() {
            current.removeClass('is-current').show();
            next.removeClass('is-next').addClass('is-current');
          });
        },6000);

        // テキストアニメーション
        var $allMsg = $('.top-kv-inner h1');
        var $wordList = $('.top-kv-inner h1').html().split("");
        $('.top-kv-inner h1').html("").show();
        $.each($wordList, function(idx, elem) {
            var newEL = $("<span/>").text(elem);
            newEL.appendTo($allMsg);
            setTimeout(function() {
              newEL.addClass('is-show');
            },idx * 300);
        });


      },150);
    });
  }
})